.home {
  background-image: url("../../assets/blob-scene-dark.svg");
  background-repeat: no-repeat;
  color: white;
  background-position: center; 
  background-size: cover; 
}

.home-left-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  visibility: hidden;
}
.home-left-container > div > h1 {
  margin-bottom: 5px;
}
.home-left-container > div > a >button {
  margin-top: 15px;
  padding: 5px 33px;
  color: #aeea00;
  background-color: #161625;
  border: 1px solid #aeea00;
}
.home-left-container > div > a > button:hover {
  opacity: 0.8;
  color: white;
  background-color: #00888f;
  border: 1px solid #00888f;
  border-radius: 5px;
}

.home-right-container {
 visibility: hidden;
}


.home-right-container > img {
  width: 80%;
  border-radius: 20px;
  color: aliceblue;
  height: 480px;
}

.home-icon {
  margin-top: 15px;
}
.home-icon > span {
  margin: 0 4px;
 
}
.home-icon > span:hover {
  color: #00888f;
  cursor: pointer;
 
}
.hi {
  color: #017a9c;
}
.type {
  color: "red" !important;
}


.company_name {
font-weight: 500;
color: #017a9c;
font-size: 20px;
}

@media (max-width: 500px) {
  .company_name{
font-weight: 500;
color: #017a9c;
font-size: 15px;
  }

  }


  @media (max-width: 800px) {
    .home {
      background-position: 0%; 
       padding-top: 50px;
    }
    .home-left-container {
      justify-content: flex-start;
    }
    }


    @media (min-width: 1200px) {
      .home {
        background-position: 0%; 
         padding: 50px 40px 40px 40px;
         height: 93vh;
      }
      }


      .home_ani_right {
        animation: home_right 1.3s ease forwards;
    }

    .home_ani_left {
      animation: home_left 1.3s ease forwards;
  }

    @keyframes home_right {
      from {
        transform: translateY(25%);
        visibility: visible;
      }
      to {
        transform: translateY(0);
        visibility: visible;
      }
    }

    @keyframes home_left {
      from {
        transform: translateY(-50%);
        visibility: visible;
      }
      to {
        transform: translateY(0);
        visibility: visible;
      }
    }
  