.contact {
  background-color: #091c34;
  color: white;
  padding-bottom: 10px;
}

.contact-img {
  width: 100%;
  height: 400px;
  margin: 0 !important;
  padding: 0 !important;
}
.contact-main {
  width: 90% !important;
  margin: 0 auto !important;
}
.contact-left-container > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 250px;
}
.balloon {
  width: 15rem;
}

.contact-form {
  width: 80%;
  color: white;
  height: 300px;
}

.contact-form > input {
  width: 100%;
  margin-top: 10px;
  outline: none;
  border-radius: 2px;
  border: 1px solid green;
  background-color: #091c34;
  padding: 7px;
  color: white;
}
.contact-form > textarea {
  margin-top: 10px;
  width: 100%;
  padding: 7px;
  outline: none;
  background-color: #091c34;
  border-radius: 2px;
  border: 1px solid green;
  color: white;
}
.contact-form > textarea:hover, .contact-form > input:hover{
  border: 1px solid white;
}
.contact-form > div > button {
  padding: 5px 20px;
  border: none;
  background-color: #091c34;
  border-radius: 2px;
  border: 1px solid green;
  margin-top: 10px;
  color: red;
}
.contact-form > div > button:hover{
  color: white;
  background-color: green;
  border: 1px solid green;
}
.contact-form > div > span {
  color: green;
  margin: 0 0 0 30px;
  font-size: 15px;
  font-weight: 500;
}
.contact-form ::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}
.contact-icon :hover {
  color: #00bd97;
  cursor: pointer;
}
.arrow {
  position: absolute;
  border: 2px solid #aeea00;
  border-radius: 49%;
  padding: 7px;
  right: 10px;
  margin-top: -53px;
}
.arrow:hover {
  background-color: #aeea00;
  cursor: pointer;
}
.arrows {
  color: white;
  font-size: xx-large !important;
}
@media (max-width: 850px) {
  .balloon {
    width: 10rem;
  }
}

@media (max-width: 767px) {
  .contact-form {
    width: 100%;
  }
  .contact-form > div > span {
    color: green;
    margin: 0 0 0 10px;
    font-size: 18px;
    font-weight: 500;
  }
}
@media (max-width: 480px) {
.ss{
  position: absolute;
}
}



.contact_ani_connect {
  animation: connect 2s ease forwards;
}

.contact_ani_hi {
  animation: hi 2s ease forwards;
}

.contact_ani_bollon {
  animation: bollon 2s ease forwards;
}

.contact_ani_github {
  animation: github 1.7s ease forwards;
}
.contact_ani_linkedin {
  animation: linkedin 2s ease forwards;
}
.contact_ani_tel {
  animation: tel 2.5s ease forwards;
}
.contact_ani_gmail {
  animation: gmail 2.5s ease forwards;
}

.contact_ani_name {
  animation: name 3s ease forwards;
}
.contact_ani_email {
  animation: email 2.5s ease forwards;
}
.contact_ani_message {
  animation: message 1s ease forwards;
}
.contact_ani_send {
  animation: send 1.8s ease forwards;
}




@keyframes connect {
  from {
    transform: translateY(-100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}


@keyframes hi {
  from {
    transform: translateY(-100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes bollon {
  from {
    transform: translateY(-100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes github {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes linkedin {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes tel {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes gmail {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes name {
  from {
    transform: translateY(-100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes email {
  from {
    transform: translateY(-100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes message {
  0% {
    transform: translateY(-100%);
    visibility: hidden;
  }
  100% {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes send {
  from {
    transform: translateY(100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}