@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,800;1,100;1,200;1,300;1,500&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden;
  overflow-y: hidden; */
}
body{
  font-family: 'Poppins', sans-serif;
}

.modals{
  color: aliceblue !important;
background-color: blue;
  
}
