.skills-main {
    background-color: #161625;
}
.skills-main > h2 {
    color : white;
    text-align: center;
    visibility: hidden;
}
.skills-highlight{
    color: #C770F0;
}
.skills-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-container {
    flex: 0.6;
    display: flex;
    justify-content: center;
}

.skill-image {
    width: 450px;
    visibility: hidden;
    /* height: 500px; */
}

.tech-stack-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.skills-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    width: 9rem;
    /* height: 100px; */
    justify-content: space-around;
    margin-bottom: 8px;
    visibility: hidden;
}

.skill-item p {
    margin: 6px;
    /* text-align: end; */
}

.skill-icon {
    width: 3.7rem;
}


.skill_img_animation_in {  
    transform: scale(1.3);
    transition: transform 0.1s;
}

.skill_img_animation_out {  
    transform: scale(1);
    transition: transform 0.2s;
    /* animation: shake 0.4s; */
}

/* @keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
} */
.reactLogoRotating {
    animation: reactLogoRotating-spin infinite 20s linear;
  }


  .ani-image {
    animation: skills_img 1.3s ease forwards;
  }
  
  .ani-item {
    animation: skills_item 1.3s ease forwards;
}

.ani-text {
    animation: skills_text 1.3s ease forwards;
}

  @keyframes skills_img {
    from {
      transform: translateY(30%);
      visibility: visible;
    }
    to {
      transform: translateY(0);
      visibility: visible;
    }
  }
  
  @keyframes skills_item {
    from {
      transform: translateY(100%);
      visibility: visible;
    }
    to {
      transform: translateY(0);
      visibility: visible;
    }
  }
  
  @keyframes skills_text {
    from {
      transform: translateY(-100%);
      visibility: visible;
    }
    to {
      transform: translateY(0);
      visibility: visible;
    }
  }

  @media (max-width: 1000px) {
    .skills-container {
        /* display: flex;
        justify-content: space-between;
        background-color: #161625;
        align-items: center; */
        flex-direction: column;
    }

    .image-container {
        flex: 1;
    }

    .skills-wrapper{
        justify-content: center;
    }
 
    }


    @media (max-width: 1000px) {
        .skill-image {
            width: 300px;
        }
    
    }

    @media (max-width: 420px) {
      .skill-item {
        width: 7.95rem;
    }
    
    .skill-item p {
        margin: 6px;
        /* text-align: end; */
    }
    
    .skill-icon {
        width: 3.7rem;
    }
  }
  
    @keyframes reactLogoRotating-spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
