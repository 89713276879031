.main {
  background-color: #151b1f;
  color: white;
  width: 100%;
}
.main > h1 {
  padding: 10px;
  text-align: center;
}

.skill-container {
  width: 80%;
  margin: 0 auto;
  
}
.language-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 1rem 0rem;
  background-color: #242526;
  color: white;
  border-radius: 10px;
  width: 200px;
  height: 90px;
}
.language-card > img {
  width: 2.3rem;
}
.language-card > h6 {
  padding-top: 0.2rem;
}
.skills-highlight {
  color: #c770f0;
}

@media (max-width: 500px) {
  .skill-container {
    width: 65%;
    margin: 0 auto;
  }
}


