.project {
  background-color: #161625;
  color: black;
}

.project > h1 {
  text-align: center;
  color: white;
  padding: 20px 0;
  visibility: hidden;
}
.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  visibility: hidden;
}
.dropdown > select {
  padding: 7px 0;
  border: none;
  outline: none;
  border-radius: 10px;
  text-align: center;
}
.project-container {
  width: 100%;
  justify-content: center;
}

.project-card {
  margin: 40px 15px;
  background-color:  'red';
  visibility: hidden;
}
.project-card > img {
  border-top-right-radius: 5px ;
  border-top-left-radius: 5px;
  width: 100%;
  height: auto;
}
.project-card > div {
  background-color: white;
}

.project-card > div > h5 {
  font-weight: 600;
  padding: 10px;
  text-align: center;
}
.project-card > div > p {
  margin: 0;
  text-indent: 40px;
  padding: 5px;
  min-height: 90px;
  max-height: 90px;
  overflow: auto;
}

.buttons {
  background-color: #fbf4f4 !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  border-bottom-left-radius:5px;
  border-bottom-right-radius: 5px;
}
.buttons button {
  background-color: #017A9C;
  padding: 6px 17px;
  border: none;
  border: 5px;
  color: white;
  border-radius: 8px;
}

.buttons button:hover {
  background-color: green;
  /* color: black; */
}

.project-highlight {
  color: #c770f0;
}


.project_ani_title {
  animation: project_title 1.3s ease forwards;
}

.project_ani_filter {
  animation: project_filter 1.3s ease forwards;
}

.project_ani_card {
  animation: project_card 1s ease forwards;
}

@keyframes project_title {
  from {
    transform: translateY(-100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}


@keyframes project_filter {
  from {
    transform: translateY(-50%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes project_card {
  from {
    width: 20rem ;
    /* visibility: visible; */
  }
  to {
    width: 21rem ;
    visibility: visible;
  }
}


@media (max-width: 420px) {
@media (max-width: 800px) {
    .footer{
flex-direction: column;
    }
    .gap{
        padding: 5px;
    }
}
.project-card {
  margin-bottom: 10px;
  margin-left: 38px;
  /* margin: 40px 15px;
  background-color:  'red';
  visibility: hidden; */
}
}
