.footer{
    background-color: #00697F;
    color: white;
    text-align: center;
    /* padding: 15px 20px 15px 20px; */
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-icon :hover{
    color: red;
    cursor: pointer;
}

.gap{
    padding: 15px;
}

@media (max-width: 800px) {
    .footer{
flex-direction: column;
    }
    .gap{
        padding: 5px;
    }
}

