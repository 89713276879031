.about-main {
  background-color: #161625;
  color: white;
  overflow: hidden;
  padding: 30px 0;
}

.about {
  width: 100%;
}

.about-highlight {
  color: #c770f0;
}

.about-main > h2 {
  text-align: center;
  visibility: hidden;
}

.a_contains {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.about-left-contain {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 0.5;
  visibility: hidden;
}

.about-right-contain {
  flex: 1;
  visibility: hidden;
  /* background-color: blue; */

}
.paragraph{
  /* background-color: red; */
}
.about-left-contain img {
  width: 600px;
  border-radius: 20px;
  padding: 30px;
}

.about-right-contain  p {
  font-size: 17.5px;
  text-indent: 30px;
  letter-spacing: 0.02rem;
  padding: 2px;
 padding: 2px 10px;

}

.about-img {
  animation: about_img 1s ease forwards;
}

@keyframes about_img {
  from {
    transform: translateY(-20%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

.about-text {
  animation: about_text 1s ease forwards;
}

@keyframes about_text {
  from {
    transform: translateY(-20%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

.about-title {
  animation: about_title 1s ease forwards;
}

@keyframes about_title {
  from {
    transform: translateY(-50%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}

@media (max-width: 1000px) {
  .about {
    width: 100%;
  }
  .a_contains {
    flex-direction: column;
  }
  .about-left-contain > img {
    width: 400px;
  }
}
